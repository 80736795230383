import React, { useState, useEffect, useContext } from 'react';
import { TransitionPortal } from 'gatsby-plugin-transition-link';

//Contexts
import { NavigationContext } from '../NavigationContext';

//Styles
import './PreLoader.scss';

const PreLoader = ({ images }) => {

	const anchorContext = useContext(NavigationContext);
	const [percLoaded, setPercLoaded] = useState(0);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		let loadedImages = 0;

		images.forEach(loaded => {
			if(loaded){
				loadedImages++;
			}
		});

		setPercLoaded(Math.round(loadedImages * 100 / images.length));
	}, [images]);

	useEffect(() => {
		if (percLoaded === 100) {
			setTimeout(() => {
				setIsLoaded(true);
			}, 500);

			setTimeout(() => {
				anchorContext.setIsLoaded(true);
			}, 1000);
		}
	}, [percLoaded, anchorContext]);

	return (
		<TransitionPortal>
			<div className={`preloader ${!isLoaded ? 'is-active' : ''}`}>
				<div className="loading-wrapper">
					<div className="load">loading...</div>
					<div className="perc">{percLoaded}%</div>
				</div>

				<div className="loading-progress" style={{width: percLoaded + '%'}}>
					<div className="loading-wrapper">
						<div className="load">loading...</div>
						<div className="perc">{percLoaded}%</div>
					</div>
				</div>
			</div>
		</TransitionPortal>
	)
}

export function useLoadImage(src){

	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const load = () => {
			let img = new Image();
			img.onload = () => {
				setIsLoaded(true);
			};
			img.src = src;
		}

		load();
	});

	return isLoaded;
};

export default PreLoader;