import { useState, useEffect } from 'react';

export function useKonami(callback) {

	const [isActive, setIsActive] = useState(false);

	useEffect(() => {

		const konami = (callback) => {
			let kkeys = [];
			// up,up,down,down,left,right,left,right,B,A
			const konami = '38,38,40,40,37,39,37,39,66,65';
			return event => {
				kkeys.push(event.keyCode);
				if (kkeys.toString().indexOf(konami) >= 0) {
					callback();
					kkeys = [];
				}
			};
		}

		const onKeyDown = konami(() => {
			setIsActive(!isActive);
		});

		window.addEventListener('keydown', onKeyDown);

		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	});

	return isActive;
};