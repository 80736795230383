import React, { useState, useEffect, useRef } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import gsap from 'gsap';

//Components
import PreviewCompatibleImage from '../PreviewCompatibleImage';

//Images
import NewIcon from '../../img/new.svg';

//Styles
import './ColoringThumbnail.scss';

const ColoringThumbnail = (props) => {

	const [isDisplayed, setIsDisplayed] = useState(false);
	let card = useRef();

	useEffect(() => {
		if (!isDisplayed){
			if(props.animate){
				gsap.fromTo(card.current, { height: 0 }, { height: 'auto', duration: 0.5, delay: props.delay });
			}
			else{
				gsap.fromTo(card.current, { height: 'auto' }, { height: 'auto' });
			}
			
			setIsDisplayed(true);
		}
	}, [props.delay, props.animate, isDisplayed]);

	return (
		<AniLink
			className="coloring-thumbnail"
			to={props.slug}
			paintDrip
			hex={props.background}
			duration={0.75}>

			<article className={`card ${props.newpost ? 'new' : ''}`} ref={card}>

				{props.thumbnail ? (
					<div className="card-image">
						<figure className="image">
							<PreviewCompatibleImage
								imageInfo={{
									image: props.thumbnail,
									alt: `${props.title}`,
								}}
							/>
						</figure>

						{props.newpost ? (
							<span className="tag is-info">
								<NewIcon />
							</span>
						) : null}
					</div>
				) : null}

				<div className="card-content has-text-centered is-overlay is-hidden-touch">
					<p className="title is-4 has-text-primary">Download Coloring Page</p>
					
					<div className="title-wrapper">
						<h3 className="title is-1 has-text-primary">{props.title}</h3>
					</div>

					<p className="title is-4 has-text-primary">{props.description}</p>
				</div>
			</article>
		</AniLink>
	)
};

export default ColoringThumbnail;