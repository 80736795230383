import React, {useState, useContext} from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { TransitionPortal } from "gatsby-plugin-transition-link";

//Contexts
import { NavigationContext } from '../NavigationContext';

//Images
import Logo from '../../img/popeyes-logo.svg';
import InstagramIcon from '../../img/social/instagram.svg';
import LogoColoringPopeyes from '../../img/logo-coloring-popeyes.svg';

//Styles
import './Navbar.scss';

const Navbar = (props) => {

	const anchorContext = useContext(NavigationContext);
	const [isBurgerOpen, setIsBurgerOpen] = useState(false);

	return (
		<TransitionPortal>
			<nav className="navbar is-fixed-top section-header" role="navigation" aria-label="main navigation">
				<div className="container">
					<div className="navbar-brand">
						<AniLink className="navbar-item" to="/" paintDrip hex="#FFE600">
							<Logo />
						</AniLink>

						<button className={`navbar-burger ${isBurgerOpen ? 'is-active' : ''}`}
							aria-label="menu" aria-expanded="false"
							onClick={() => { setIsBurgerOpen(!isBurgerOpen) }}
						>
							<span aria-hidden="true"></span>
							<span aria-hidden="true"></span>
							<span aria-hidden="true"></span>
						</button>
					</div>

					<div className={`navbar-menu ${isBurgerOpen ? 'is-active' : ''}`}>

						<div className="navbar-start">
							<div className="navbar-item">
								<LogoColoringPopeyes className="logo" />
							</div>
						</div>

						<div className="navbar-end is-lowercase">
							<AniLink className="navbar-item" to="/" paintDrip hex="#FFE600" onClick={() => { setIsBurgerOpen(false); }}>Coloring pages</AniLink>
							<AniLink className="navbar-item" to="/" paintDrip hex="#FFE600" onClick={() => { anchorContext.setAnchor('#about'); setIsBurgerOpen(false); }}>About</AniLink>
							<AniLink className="navbar-item" to="/" paintDrip hex="#FFE600" onClick={() => { anchorContext.setAnchor('#credits'); setIsBurgerOpen(false); }}>Credits</AniLink>
							
							<a className="navbar-item" href="https://www.instagram.com/popeyes.art/" target="_blank" rel="noopener noreferrer">
								<InstagramIcon style={{ width: '1em', height: '1em' }} />
							</a>
						</div>
					</div>
				</div>
			</nav>
		</TransitionPortal>
	);
};

export default Navbar;
