import React, { useRef, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

//Contexts
import { NavigationContext } from './NavigationContext';

//Components
import PreLoader, { useLoadImage } from './PreLoader/PreLoader'
import Navbar from '../components/Navbar/Navbar';
import { useKonami } from './Konami';

//Styles
import './styles.scss';

const Layout = ({ children, background }) => {
	const { title, description, siteURL } = useSiteMetadata();

	const anchorContext = useContext(NavigationContext);

	let isKonamiActive = useKonami();
	let konamiInterval = useRef();

	let bart = useLoadImage(`${withPrefix('/')}img/keyvisual/bart.png`);
	let wario = useLoadImage(`${withPrefix('/')}img/keyvisual/wario.png`);
	let spongebob = useLoadImage(`${withPrefix('/')}img/keyvisual/spongebob.png`);
	let kermit = useLoadImage(`${withPrefix('/')}img/keyvisual/kermitt.png`);
	let astrix = useLoadImage(`${withPrefix('/')}img/keyvisual/asterix.png`);
	let yellowPen = useLoadImage(`${withPrefix('/')}img/keyvisual/yellow-pen.png`);

	useEffect(() => {

		const setBackgroundColor = (color) => {
			document.getElementsByTagName('html')[0].style.background = color;
			document.documentElement.style.setProperty('--theme-color', color);
		};
		
		if (!isKonamiActive) {
			clearInterval(konamiInterval.current);
			setBackgroundColor(background ? background : '#FFE600');
		}
		else {
			konamiInterval.current = setInterval(() => {
				setBackgroundColor('#' + Math.random().toString(16).slice(-6));
			}, 500);

			trackCustomEvent({
				category: 'Global',
				action: 'KonamiCode'
			});
		}

		return () => {
			clearInterval(konamiInterval.current);
		};
		
	}, [background, isKonamiActive]);

	return (
		<ParallaxProvider>
			<Helmet>
				<html lang="en" className="has-navbar-fixed-top" />
				<title>{title}</title>
				<meta name="description" content={description} />

				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href={`${withPrefix('/')}img/apple-touch-icon.png`}
				/>
				<link
					rel="icon"
					type="image/png"
					href={`${withPrefix('/')}img/favicon-32x32.png`}
					sizes="32x32"
				/>
				<link
					rel="icon"
					type="image/png"
					href={`${withPrefix('/')}img/favicon-16x16.png`}
					sizes="16x16"
				/>

				<meta property="og:locale" content="en_EN" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content={title} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={siteURL} />
				<meta property="og:image" content={`${siteURL}${withPrefix('/')}img/og-image.png`} />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={`${siteURL}${withPrefix('/')}img/og-image.png`} />
			</Helmet>

			<Navbar />

			<section className="section">
				<div className="container">
					{children}
				</div>
			</section>

			<div className="footer"></div>

			{!anchorContext.isLoaded ?
				<PreLoader images={[bart, wario, spongebob, kermit, astrix, yellowPen]} />
			: null}
			
		</ParallaxProvider>
	)
};

export default Layout;
