import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';

import ColoringThumbnail from './ColoringThumbnail/ColoringThumbnail';

const ColoringList = ({ data, itemsCount, random, exclude, columnWidth, more, responsive = true }) => {
	const { edges: posts } = data.allMarkdownRemark;

	const [diplayedItemsCount, setDiplayedItemsCount] = useState(itemsCount);
	const [coloringThumbnails, setColoringThumbnails] = useState(posts);
	const [displayMoreButton, setDisplayMoreButton] = useState(more);
	const [isMobile, setIsMobile] = useState(false);

	let itemsList = useRef(_.clone(posts));

	useEffect(() => {

		const mobileDetection = () => {
			if(responsive){
				if (window.innerWidth <= 769) {
					setIsMobile(true);
					setDiplayedItemsCount(diplayedItemsCount + (diplayedItemsCount % 2));
				}
				else {
					setIsMobile(false);
					setDiplayedItemsCount(diplayedItemsCount - (diplayedItemsCount % 3));
				}
			}
		}
		mobileDetection();

		window.addEventListener('resize', mobileDetection);
		
		return () => {
			window.removeEventListener('resize', mobileDetection);
		};
	}, [diplayedItemsCount, responsive]);

	useEffect(() => {
		if (random) {
			itemsList.current = _.shuffle(itemsList.current);
		}
	}, [random]);
	
	useEffect(() => {
		let items = _.clone(itemsList.current);
		let itemsShowUpCount = isMobile ? 2 : 3;

		if (exclude != null) {
			_.remove(items, { node: { id: exclude }});
		}

		if (diplayedItemsCount != null) {
			items = _.slice(items, 0, diplayedItemsCount);

			let displayDelay = 0;
			items.forEach((item, index) => {
				item.delay = 0;
				item.animate = true;

				if (index >= items.length - itemsShowUpCount){
					item.delay = displayDelay;
					displayDelay += 0.25;
				}

				if (diplayedItemsCount <= itemsCount){
					item.animate = false;
				}
			});
		}

		if (items.length === itemsList.current.length) {
			setDisplayMoreButton(false)
		}

		setColoringThumbnails(items);
	}, [exclude, diplayedItemsCount, itemsCount, isMobile]);

	const showMore = () => {
		let itemsShowUpCount = isMobile ? 2 : 3;
		setDiplayedItemsCount(diplayedItemsCount + itemsShowUpCount);
	};

	return (
		<>
			<div className="columns is-centered is-multiline is-mobile">
				{coloringThumbnails &&
					coloringThumbnails.map(({ node: post, delay, animate }) => {

						return (
							<div className={`column is-${columnWidth ? columnWidth : 4}-desktop is-${columnWidth ? columnWidth : 4}-tablet is-half-mobile`} key={post.id}>

								<ColoringThumbnail
									title={post.frontmatter.title}
									description={post.frontmatter.description}
									slug={post.fields.slug}
									background={post.frontmatter.background}
									newpost={post.frontmatter.newpost}
									thumbnail={post.frontmatter.thumbnail}
									delay={delay}
									animate={animate} />

							</div>
						)
					})}
			</div>

			{displayMoreButton ? (
				<div className="has-text-centered">
					<button className="button is-more" onClick={showMore}>More</button>
				</div>
			) : null }
		</>
	);
};

ColoringList.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
	itemsPerRow: PropTypes.string
};

export default ({ itemsCount, random, exclude, columnWidth, more, responsive}) => (
	<StaticQuery
		query={graphql`
			query ColoringListQuery {
				allMarkdownRemark(
					sort: { order: DESC, fields: [frontmatter___date] }
					filter: { frontmatter: { templateKey: { eq: "coloring-post" } } }
				) {
					edges {
						node {
							excerpt(pruneLength: 400)
							id
							fields {
								slug
							}
							frontmatter {
								title
								description
								templateKey
								newpost
								background
								date(formatString: "MMMM DD, YYYY")
								thumbnail {
									childImageSharp {
										fluid(maxWidth: 500, quality: 100) {
											...GatsbyImageSharpFluid_withWebp_tracedSVG
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data, count) =>
			<ColoringList
				data={data} count={count}
				itemsCount={itemsCount} random={random} exclude={exclude}
				columnWidth={columnWidth}
				more={more}
				responsive={responsive}
			 />
		}
	/>
);
